import { DateTime, Interval } from "luxon"
import { zeroPadding, Logger, onlyUnique, funcName } from "packs/common"

import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import axios from "types/axios"
import NotificationControl from "packs/utils/NotificationControl"
import Notice from "../Notice"

export default class AstagsTab {
    constructor(
        public astags: AvailableScheduleTag[],
        public shared_astags: AvailableScheduleTag[],
        public searched_astags: AvailableScheduleTag[],

        // 仲介が、提案者からもらって、作成した調整カレンダー
        public shared_chukai_astags: AvailableScheduleTag[],

        public tab_name: string,

        // default
        public has_next: boolean,
        public total_astags_num: number,
        public page: number,
        public total_astag_page: number,

        // shared
        public shared_has_next: boolean,
        public shared_total_astags_num: number,
        public shared_page: number,
        public shared_total_astag_page: number
    ) {}

    static copy(t: AstagsTab): AstagsTab {
        let clone = Object.assign(Object.create(Object.getPrototypeOf(t)), t)
        return clone
    }

    /**
     *
     */
    static createNew(astags: AvailableScheduleTag[], shared_astags: AvailableScheduleTag[], info: any): AstagsTab {
        Logger(`AstagsTab.createNew astags:${astags?.length}, shared_astags:${shared_astags?.length}, info:${Util.output(info)}`)
        if (!info) return

        return new AstagsTab(
            astags,
            shared_astags,
            null,
            [],
            `default`,
            info.has_next,
            info.total_astags_num,
            info.page,
            info.total_page,
            true,
            0,
            0,
            0
        )
    }

    /**
     * リモートから取得してきたデータをタブにセットします。
     * @param tab 選択しているタブ.
     * @param data リモートからもらってきたデータ.
     * @param userInfo 自分のユーザー情報
     * @param type default/shared
     */
    static updateCurrentFetch(
        astagsTab: AstagsTab,
        astags: AvailableScheduleTag[],
        has_next,
        total_astags_num,
        page,
        total_astag_page,
        type: string
    ): AstagsTab {
        let asts = [...(astagsTab.astags || [])]
        if (type == `shared`) {
            asts = [...(astagsTab.shared_astags || [])]
        }
        Logger(`astagsTab.updateCurrentFetch type:${type} asts:${asts?.length}, astags:${astags?.length}`)

        let astagIds = asts.map(astag => astag.id)
        for (let ast of astags) {
            if (astagIds.includes(ast.id)) {
                continue
            }
            asts.push(ast)
        }
        // Array.prototype.push.apply(asts, astags)

        if (type == `default`) {
            astagsTab.astags = asts
            astagsTab.has_next = has_next
            astagsTab.total_astags_num = total_astags_num
            astagsTab.page = page
            astagsTab.total_astag_page = total_astag_page
        } else if (type == `shared`) {
            astagsTab.shared_astags = asts
            astagsTab.shared_has_next = has_next
            astagsTab.shared_total_astags_num = total_astags_num
            astagsTab.shared_page = page
            astagsTab.shared_total_astag_page = total_astag_page
        }

        return astagsTab
    }

    /**
     * 調整カレンダーを取得します.
     * @param tab
     * @param type default/shared
     * @returns
     */
    static getNextAstags(tab: AstagsTab, type: string = `default`): Promise<any> {
        Logger(`AstagsTab.getNextAstags type:${type}`)
        let page = tab.page + 1
        if (type == `shared`) {
            page = tab.shared_page + 1
        }

        let params = { page: page, type: type }

        return axios
            .get(`${Util.prefixUrl}/available_schedule_tags`, params)
            .then(res => {
                if (res.data) {
                    const astagsData = res.data.astags
                    let newAstags = AvailableScheduleTag.fetchFromJson(astagsData)

                    let tabInfo = res.data.astags_tab_info
                    tab = AstagsTab.updateCurrentFetch(
                        tab,
                        newAstags,
                        tabInfo.has_next,
                        tabInfo.total_astags_num,
                        tabInfo.page,
                        tabInfo.total_page,
                        tabInfo.type
                    )
                }
                return { astags: tab.astags, tab: tab }
            })
            .catch(err => {
                Logger(`rm.${funcName()} err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                const res = err.response ? err.response : null
                if (res) {
                    if (res.status == 400) {
                        Notice.message = `セッションが切れました。ログインし直してください。`
                        location.href = `/`
                    }
                }
                return null
            })
    }

    static search(name: string) {
        const params = { name: name }

        Logger(`request search:${Util.output(params)}`)

        return axios
            .get(`${Util.prefixUrl}/available_schedule_tags/search`, params)
            .then(res => {
                Logger(`AvailableScheduleTag#search res:${Util.output(res.data)}`)
                if (res.data) {
                    let asts = AvailableScheduleTag.fetchFromJson(res.data.astags)
                    return asts
                }
                return []
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
