import axios from "axios"
import CalendarTag from "packs/models/CalendarTag"
// import DateTag from "./DateTag"
// import { ScheduleManager } from "./ScheduleManager"
import Util from "packs/utils/Util"
import { DateTime } from "luxon"
// import { Calendar } from "fullcalendar"
import { Logger, funcName } from "packs/common"
import Notice from "packs/models/Notice"
import Event from "packs/models/Event"
import NotificationControl from "packs/utils/NotificationControl"
// import AvailableScheduleTag from "./AvailableScheduleTag"

interface PropertyStore {
    ctList: CalendarTag[] // Google、MSから取得してきた全てのArray<CalendarTag>
    // getCalendarSettingsInfo(): Promise<any>
    getCalList(): Promise<any>
    getEvents(ctags: CalendarTag[])
}

//  データストア
let _PropertyStore: PropertyStore = {
    ctList: [], // Google、MSから取得してきた全てのArray<CalendarTag>

    /**
     * 連携しているカレンダー一覧を取得してきます。
     * selectedCtagsが存在する場合、そのctag.idと比較してチェックボックスにチェックとignore_alldayを設定します。
     */
    getCalList(): Promise<any> {
        return axios
            .get(`${Util.prefixUrl}/calendar_tags`)
            .then(res => {
                let ctList: CalendarTag[] = CalendarTag.fetchFromJson(res.data.calendar_tags)
                this.ctList = ctList
                Logger(`PropertyStore.${funcName()} ctList: ${Util.output(ctList)}`)

                // 今までどおり代入.
                this.ctList = ctList
                this.ctags = this.ctList

                return this.ctags
            })
            .catch(err => {
                Logger(`PropertyStore.${funcName()} err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    },
    /**
     * Ctagsを利用したpossible_datesを叩きます。
     */
    getEvents(ctags: CalendarTag[]): Promise<any> {
        const _ctags = ctags
        Logger(`PropertyStore.${funcName()} 選択しているctags: ${Util.output(_ctags)}`)

        const selectedCtags = _ctags.map(c => {
            return { id: c.id, ignore_allday: c.ignore_allday }
        })
        const params = { calendar_tags: selectedCtags }
        // Getリクエストのパラムをオブジェクト化するオプション
        return axios
            .get(`${Util.prefixUrl}/calendar_tags/events`, { params: params })
            .then(result => {
                Logger(`PropertyStore.${funcName()} result: ${Util.output(result.data)}`)
                const _dailyEvents = result.data.daily_events
                let dailyEvents = {}
                Object.entries(_dailyEvents).forEach(([date, events]) => {
                    let _events: Event[] = events as any
                    let evs = []
                    for (let ev of _events) {
                        ev.systemUpdatedAt = DateTime.local().toMillis() + ev.start_time
                        evs.push(ev)
                    }
                    dailyEvents[date] = evs
                })

                return dailyEvents || {}
            })
            .catch(err => {
                Logger(`PropertyStore.${funcName()} err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return {}
            })
    },
}
export default _PropertyStore
