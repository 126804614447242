import CalendarTag from "packs/models/CalendarTag"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"
import Room from "packs/models/Room"
import NotificationControl from "packs/utils/NotificationControl"
import Appointment from "./Appointment"
import Notice from "./Notice"
import axios from "axios"
import InstantRoomAssignedUser from "./InstantRoomAssignedUser"

export default class MeetingAttendeesGroup {
    constructor(
        public id: string,
        public required_attendees: CalendarTag[],
        public optional_attendees: CalendarTag[],
        public registrable_attendees: CalendarTag[],
        public event_owner: CalendarTag,
        public systemUpdatedAt: number,
        public name: string,
        public team_name: string,
        public description: string,
        public profile_image_urls: string[],
        public emails: string,
        public reply_emails: string,

        public can_select_teams: boolean,
        public can_select_google_meet: boolean,
        public can_select_zoom: boolean,
        public zoom_account_email: string,
        public priority: number,
        public can_select_free_location: boolean,
        public meeting_room_resources: any[],
        public online_meeting_resources: any[],
        public selected: boolean,
        public can_add_emails_auto: boolean,
        public is_active_meeting_attendees_group_option: boolean,
        public daily_meeting_max_num: number,
        public daily_countable_event_keyword: string,
        public purpose_type: string,
        public variable_1: string,
        public variable_2: string,

        public instant_room_assigned_user: InstantRoomAssignedUser,
        public calendar_name_dic: any
    ) {}

    static fetchFromJson(magsJson: any[]): MeetingAttendeesGroup[] {
        let mags = []
        // Logger(`magsJson: ${Util.output(magsJson)}`)
        if (!magsJson) return [this.createDefault()]
        // Logger(`magsJson: ${Util.output(magsJson.length)}`)
        if (magsJson.length == 0) return [this.createDefault()]

        for (let _mag of magsJson) {
            // magsJson.map(_mag,index => {
            const _req_atts: CalendarTag[] = CalendarTag.fetchFromJson(_mag.required_attendees, "mag") as CalendarTag[]
            const _op_atts: CalendarTag[] = CalendarTag.fetchFromJson(_mag.optional_attendees, "mag") as CalendarTag[]
            const _regi_atts: CalendarTag[] = CalendarTag.fetchFromJson(_mag.optional_attendees, "mag") as CalendarTag[]
            const _ev_owner: CalendarTag = CalendarTag.fetchFromJson([_mag.event_owner], "mag")[0]
            const _t = Util.getSec()
            // let defaultMag = this.createDefault()
            // const mag = Object.assign(defaultMag, _mag)
            const mag = new MeetingAttendeesGroup(
                _mag.id,
                _req_atts,
                _op_atts,
                _regi_atts,
                _ev_owner,
                _t,
                ``,
                _mag.team_name,
                _mag.description,
                _mag.profile_image_urls,
                _mag.emails,
                _mag.reply_emails,
                _mag.can_select_teams,
                _mag.can_select_google_meet,
                _mag.can_select_zoom,
                _mag.zoom_account_email,
                _mag.priority,
                _mag.can_select_free_location,
                _mag.meeting_room_resources,
                _mag.online_meeting_resources,
                false,
                _mag.can_add_emails_auto,
                _mag.is_active_meeting_attendees_group_option,
                _mag.daily_meeting_max_num,
                _mag.daily_countable_event_keyword,
                _mag.purpose_type,
                _mag.variable_1,
                _mag.variable_2,
                _mag.instant_room_assigned_user,
                _mag.calendar_name_dic
            )

            mags.push(mag)
            // })
        }
        mags = this.addNames(mags)
        return mags
    }

    static createDefault(purposeType: string = `normal`): MeetingAttendeesGroup {
        const _time = Util.getSec()
        return new MeetingAttendeesGroup(
            null,
            [],
            [],
            [],
            null,
            _time,
            ``,
            ``,
            ``,
            null,
            null,
            null,
            true,
            true,
            true,
            null,
            1,
            false,
            null,
            null,
            false,
            true,
            false,
            -1,
            null,
            purposeType,
            null,
            null,
            null,
            null
        )
    }

    static isPresent(mag: MeetingAttendeesGroup): boolean {
        if (!mag || !mag.required_attendees) return false
        return mag.required_attendees.length > 0 && mag.purpose_type != `instant_room`
    }

    static isInstant(mag: MeetingAttendeesGroup): boolean {
        return mag.purpose_type == `instant_room`
    }

    /**
     * 新規のastagで、カレンダー連携をした場合にデフォルトで、日本の祝日と自分のカレンダー（emailList）にあるものを必須参加者として登録します。
     * @param astag [AvailableScheduleTag] セットするastag
     * @param ctagList [CalendarTag[]] MS/Googleから取得してきたすべてのctags
     * @param emailList [string] デフォルトで登録するctag.emailの名前
     */
    static setRequiredAttendeesGroup(
        astag: AvailableScheduleTag,
        ctagList: CalendarTag[],
        emailList: string[]
    ): AvailableScheduleTag {
        const ctags = ctagList.filter(ct => emailList.includes(ct.email))
        let mag: MeetingAttendeesGroup = { required_attendees: ctags } as MeetingAttendeesGroup
        astag.meeting_attendees_groups = [mag]
        return astag
    }

    /**
     * astagの中にある必須参加者(ctags)を取得してきます。
     * @param astag [AvailableScheduleTag]
     * @param index [number] 必須参加者を取得するattendeesGroupsの順番. OR で複数MeetingAttendeesGroup[]をastagが持っているため.
     */
    static getRequiredAttendees(astag: AvailableScheduleTag, index = 0): CalendarTag[] {
        if (!astag) return []

        let meetingAttendeesGroups = astag.meeting_attendees_groups
        if (!meetingAttendeesGroups || meetingAttendeesGroups.length == 0) return []

        const _ctags = meetingAttendeesGroups[index].required_attendees
        return _ctags
    }

    /**
     * 上から順に名前を ABC...でつけていきます。
     * @param mags
     */
    static addNames(mags): MeetingAttendeesGroup[] {
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        return mags.map((_mag, index) => {
            let name = ``
            let currentIndex = index
            while (currentIndex >= 0) {
                name = alphabet[currentIndex % alphabet.length] + name
                currentIndex = Math.floor(currentIndex / alphabet.length) - 1
            }
            _mag.name = name
            return _mag
        })
    }

    /**
     * プロフィールに記載する内容に不足がある場合、warningのテキストをarrayで返却します。
     * @param mag [MeetingAttendeesGroup]
     * @return text [string[]]
     */
    static getWarningsInProfile(mag: MeetingAttendeesGroup): string[] {
        let text = []
        if (!mag.team_name) {
            text.push(`チーム名/担当者名の記載がないため表示されません`)
        }
        if (!mag.description) {
            // なくてもよしとする
            // text.push(`紹介文に記入がありません`)
        }
        if (!mag.profile_image_urls) {
            // なくてもよしとする
            // text.push(`担当者の画像がありません`)
        }
        return text
    }

    static changeAssignedMag(
        room: Room,
        mag: MeetingAttendeesGroup,
        notifyMembers: boolean,
        message: string,
        locationName: string,
        zoomAccs: CalendarTag[],
        use_current_url: boolean = false
    ) {
        if (mag.purpose_type == `temporary`) {
            mag.online_meeting_resources = zoomAccs
        }
        return axios({
            method: `put`,
            url: `${Util.prefixUrl}/room_appointments/change_meeting_attendees`,
            data: {
                parent_room_id: room.id,
                room_appointment_id: room.current_appointment.id,
                group_id: mag.id,
                notify_room_members: notifyMembers,
                user_message: message,
                new_group: mag.id ? null : mag,
                location_name: locationName,
                use_current_url: use_current_url,
            },
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                const res_appo: Appointment = Appointment.fetchFromJson([res.data.appointment])[0]

                return { appo: res_appo }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                // if (res.code === "ng_052") {

                // } else {
                //     Notice.message = `日程調整情報をうまく更新できませんでした。後ほどお試しください。`
                // }
                return null
            })
    }
}
