import axios from "axios"
import { DateTime } from "luxon"
import { Logger, funcName } from "packs/common"
import Room from "packs/models/Room"
import Util from "./Util"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Appointment from "packs/models/Appointment"

//  便利系を記載.
export default class HeaderControl {
    static readonly defaultTitle = `クラウド日程調整 | waaq Link（ワークリンク）`

    /**
     * ルーム一覧で使用. 1つでも未読のメッセージがあるルームが存在する場合、ヘッダーに(1)を追加します。
     * @param rooms [Room[]] 原則this.rm.roomsにあるすべてのルームを見ますが、openのルームだけを渡してもいいです。
     */
    static updateHeaderTitleWithUnreadNum(rooms: Room[]): string {
        for (const _room of rooms) {
            if (!Room.isParentActiveWithoutCanceled(_room.status)) continue

            if (_room.unread_num > 0) {
                return this.changeTitle(true)
            }
            let child = _room.children.find(r => r.unread_num > 0)
            if (child) {
                return this.changeTitle(true)
            }
        }
        return this.changeTitle(false)
    }

    /**
     * 既読未読のヘッダーにあるタイトルを変更します。
     * @param hasUnread [boolean] 未読があるか。falseの場合、defaultのタイトルに戻します。
     * @param num [number] 一覧の場合は1のみ、調整ページ内では未読の合計数
     */
    static changeTitle(hasUnread: boolean, num: number = 1): string {
        Logger(`HeaderControl.${funcName()} hasUnread:${hasUnread}, num:${num}`)
        if (!document) return

        if (hasUnread) {
            document.title = `(${num}) ${this.defaultTitle}`
            return document.title
        }
        document.title = `${this.defaultTitle}`
        return document.title
    }

    /**
     * 他のページでタイトルを更新したい場合はこちら.
     */
    static updateTitle(name: string) {
        if (!document) return
        document.title = `${name} - ${this.defaultTitle}`
    }

    /**
     * #/fix_scheduleで利用.
     * この親子ルームで未読の件数をタイトルに反映します。
     * @param rooms [Room[]]
     */
    static countUnreadNumAndUpdateTitle(rooms: Room[]) {
        if (!document) return
        let totalUnreadNum = 0
        rooms.map(r => (totalUnreadNum += r.unread_num))
        Logger(`HeaderControl.${funcName()} この親子ルームでの未読数:${totalUnreadNum}件`)
        if (totalUnreadNum > 0) {
            this.changeTitle(true, totalUnreadNum)
        } else {
            this.changeTitle(false)
        }
    }

    static getScheduleViewTitle(type: string, appo: Appointment): string {
        let title = ""
        // if (!this.showPossibleDatesTypes.includes(this.type)) return "";
        if (appo && appo.status == Appointment.statusCancel) {
            return "schedule.canceled"
        }

        if (type == `fill`) {
            title = "schedule.filled"
            // title = "予定が埋まっています"
        } else if (type == `overstart`) {
            title = "schedule.overstart"
            // title = "開始日時が過ぎています"
        } else if (type == `overdue`) {
            title = "schedule.overdue"
            // title = "期限が切れています"
        } else if (type == `confirm`) {
            title = "schedule.confirm"
            // title = "日程を確認"
        } else if (type == `already`) {
            title = "schedule.already"
            // title = "確定した日程"
        } else if (type == `editable`) {
            title = "schedule.confirm"
        }
        return title
    }

    /**
     * 調整ページで表示するテキストを変更します。
     * @param type
     */
    static getScheduleViewText(type: string, currentPossibleDates: PossibleDate[], pdm: any, appo: Appointment): string {
        let title = ``
        if (appo && appo.status == Appointment.statusCancel) {
            return "こちらの日程調整はキャンセルされました。新規の調整を開始する場合は、上記の「同じメンバーで新規の日程調整を開始」ボタンから開始するか、オーナーが新しい調整ページを作成してください。"
        }

        if (type == `fill`) {
            title = "すでに予定が埋まっています。別日程をお選びください。"
        } else if (type == `overstart`) {
            title = "すでに開始可能な日程を過ぎました。別日程をお選びください。"
        } else if (type == `overdue`) {
            title = "すでにご登録可能な期限を経過しました。別日程をお選びください。"
        } else if (type == `confirm`) {
            // title = "日程を確認"
            if (currentPossibleDates && currentPossibleDates.length > 0) {
                if (pdm.canFix) {
                    title = `確定できる日程が${currentPossibleDates.length}件あります。`
                } else {
                    title = `確定できるユーザーが日程を確定するまでしばらくお待ち下さい。`
                }
            }
            if (appo && appo.status == 10) {
                title = `以下の日程で確定しています。`
            }
        } else if (type == `already`) {
            title = "下記の日程で確定しています。"
        }
        return title
    }
}
