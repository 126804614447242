import { DateTime } from "luxon"
import axios from "axios"
import Util from "packs/utils/Util"
import { funcName, Logger } from "packs/common"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import PossibleDate from "packs/models/PossibleDate"
import RoomMember from "packs/models/RoomMember"
import NotificationControl from "packs/utils/NotificationControl"
import RoomSetting from "packs/models/RoomSetting"
import DateTag from "packs/models/DateTag"

interface ZoomSetting {
    join_before_host: boolean
    waiting_room: boolean
    alternative_hosts: string
    passcode_type: string
}

export default class UserSetting {
    constructor(
        public submit_lower_limit_num: number, // 三者間以上での提出する下限件数
        public email_suggests_num: number, // メール上に記載する候補件数
        public default_duration: number, // デフォルト所要時間を設定.
        public prefix_subject: string,
        public mail_display_type: string, // rich, simple
        public chat_notification_display_type: string, // display, none
        public signature_text: string,

        // every_15_min,every_30_min,hourly,odd_hourly,even_hourly,custom
        public step_size_type: string,
        /**
         * [
         *   {wday:[1, 3], available_time: {9=> [0], 10=> [30], 18=> [0, 45]}},
         *   {wday:[2], available_time: {10=> [0], 11=> [30], 19=> [0]}}
         * ]
         */
        public available_start_times: any[],
        // same_event, other_event
        public add_event_type: string,
        // キャンセルボタンをいつまで表示させるか.
        public available_cancel_min_before_appo_start_time: number,
        // 確定前何分まで有効か.
        public available_fix_min_before_appo_start_time: number,
        public zoom_setting: ZoomSetting,

        // 投票ページ作成時に自動で抽出する最大件数
        public auto_suggested_vote_possible_dates_max_num: number,
        public available_days_of_weeks: DateTag[],
        public provider_event_title: string,
        public provider_event_description: string,

        public reply_emails: string,
        public can_change_appointment_from_other: boolean,
        public timezone_value: string
    ) {}

    static fetchFromJson(usJson: any): UserSetting {
        let us = this.createDefault()
        let newUs = usJson as UserSetting
        const returnedTarget = Object.assign(us, newUs)
        return returnedTarget
    }

    static createDefault(): UserSetting {
        let zs = { join_before_host: true } as ZoomSetting
        return new UserSetting(
            -1,
            3,
            60,
            null,
            `rich`,
            `none`,
            null,
            `hourly`,
            null,
            `same_event`,
            null,
            null,
            zs,
            15,
            [],
            null,
            null,
            ``,
            true,
            null
        )
    }

    /**
     * アポを作成/更新します。
     * @param us [UserSetting]
     * @param endpoint [string] step_size / lower_limit_num / email_suggests_num / default_duration / prefix_subject
     * @param showMessage [boolean] 取得後の変更メッセージを表示します.
     */
    static update(us: UserSetting, endpoint: string, showMessage: boolean = true): Promise<any> {
        // appointment["parent_room_id"] = ""
        Logger(`送信するuser_setting: ${Util.output(us)}`)

        return axios({
            method: "put",
            url: `${Util.prefixUrl}/user_settings/${endpoint}`,
            data: { user_setting: us },
        })
            .then(res => {
                Logger(`UserSetting.update res:${res.data.message} ${Util.output(res.data)}`)
                // if (res.data.message && showMessage) {
                //     // Notice.message = `${res.data.message}`
                //     Notice.setMessage(res.data.message)
                // }
                const _us: UserSetting = this.fetchFromJson(res.data.user_setting)

                return { us: _us, mes: res.data.message }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static disconnectCalendar() {
        return axios({
            method: "delete",
            url: `${Util.prefixUrl}/user_settings/disconnect_calendar`,
            data: null,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }

                return true
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static supportWaaq(support: boolean) {
        return axios({
            method: "put",
            url: `${Util.prefixUrl}/user_groups/support_waaq`,
            data: { can_support: support },
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                let support = res.data.can_support

                return support
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static toRoomSetting(us: UserSetting): RoomSetting {
        let rs = RoomSetting.createDefault()
        rs.duration = us.default_duration
        rs.step_size_type = us.step_size_type
        rs.available_start_times = us.available_start_times
        rs.add_event_type = us.add_event_type
        rs.zoom_setting = us.zoom_setting
        rs.email_suggests_num = us.email_suggests_num
        rs.submit_lower_limit_num = us.submit_lower_limit_num
        rs.available_days_of_weeks = us.available_days_of_weeks
        rs.provider_event_title = us.provider_event_title
        rs.provider_event_description = us.provider_event_description
        return rs
    }

    static saveMyNotifications(notis: string[]) {
        Logger(`${funcName()}`)

        return axios({
            method: "put",
            url: `${Util.prefixUrl}/user_settings/my_notifications`,
            data: { my_notifications: notis },
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                // const _us: UserSetting = this.fetchFromJson(res.data.user_setting)

                return res.data.my_notifications
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
