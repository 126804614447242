import RoomMember from "./RoomMember"
import axios from "axios"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import Notice from "./Notice"
import { DateTime } from "luxon"
import NotificationControl from "packs/utils/NotificationControl"
import Appointment from "./Appointment"
import CalendarTag from "./CalendarTag"

export default class LocationTag {
    constructor(
        public id: string,
        public name: string,
        public address: string,
        public keyword: string,
        public status: number,
        public location_type: string, // online / offline
        public location_type_name: string, // オンライン
        public public_id: string,
        public can_travel_time: boolean,
        public lat: number,
        public lng: number,
        public hidden: boolean,
        public is_shared: boolean,
        public created_at: number,
        public updated_at: number,
        public private_memo: string,
        public provider: string,
        public selected: boolean,
        public zoom_host_key: string // zoomのオーナー編集の場合のみ
    ) {}

    static createDefault(): LocationTag {
        let now = Util.getSec()
        return new LocationTag(
            null,
            ``,
            ``,
            ``,
            10,
            `offline`,
            `オフライン`,
            null,
            null,
            null,
            null,
            false,
            false,
            now,
            now,
            ``,
            null,
            false,
            null
        )
    }

    /**
     * アポの場所を取得します。
     * 場所の位置を住所から取得したいため、selected_locationがあればそこから住所を取得、なければlocation_nameを返します。
     *
     */
    static getAddressFromCurrentAppo(appo: Appointment, useLatLng: boolean = false) {
        if (appo?.selected_location?.address) {
            if (useLatLng && appo?.selected_location?.lat && appo?.selected_location?.lng) {
                return `${appo?.selected_location?.lat},${appo?.selected_location?.lng}`
            }
            return appo?.selected_location?.address
        }
        return appo?.location_name
    }

    static createRegistrable(): LocationTag {
        let _loctag = this.createDefault()
        _loctag.address = null
        _loctag.keyword = `自由に場所を入力できます`
        _loctag.public_id = `free`
        return _loctag
    }

    locname() {
        return `${this.name} (${this.address})`
    }

    static fetchFromJson(loctagsJson: any): LocationTag[] {
        let loctags = []
        if (!loctagsJson || loctagsJson.length == 0) return []

        for (let loctag of loctagsJson) {
            const _loctag: LocationTag = loctag
            loctags.push(_loctag)
        }
        return loctags
    }

    /**
     * 招待したことのあるユーザーを取得してきます。
     */
    static getLocations(): Promise<LocationTag[]> {
        return axios
            .get(`${Util.prefixUrl}/user_settings/locations`)
            .then(res => {
                const _locations = res.data.locations
                if (!_locations) return []
                let loctags = LocationTag.fetchFromJson(_locations)
                return loctags
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static save(loctag: LocationTag, sendType, additional_params = null): Promise<LocationTag> {
        Logger(
            `LocationTag.save sendType:${sendType}, loctag:${loctag?.id} keyword:${
                loctag?.keyword
            }, additional_params:${Util.output(additional_params)}`
        )

        if (additional_params) {
            loctag = Object.assign(loctag, additional_params)
        }

        return axios({
            method: sendType,
            url: `${Util.prefixUrl}/user_settings/locations`,
            data: loctag,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                const loctag: LocationTag = LocationTag.fetchFromJson([res.data.location])[0]

                return loctag
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static delete(loctag: LocationTag) {
        let endpoint = `locations`
        if (loctag.location_type == `online_oauth`) {
            // oauthのため、削除先のエンドポイントを変えます.
            if (loctag.provider == `google_for_link`) {
                endpoint = `google_meet`
            } else if (loctag.provider == `ms_for_link`) {
                endpoint = `teams`
            } else {
                endpoint = loctag.provider
            }
        }
        let params = { id: loctag.id }

        return axios
            .delete(`${Util.prefixUrl}/user_settings/${endpoint}`, { data: params })
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`
                let _loc = res.data.location
                if (_loc) {
                    return _loc
                } else {
                    return null
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    static saveDefault(loctag: LocationTag) {
        let params = { id: loctag ? loctag.id : null }

        return axios
            .post(`${Util.prefixUrl}/user_settings/locations/default`, params)
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`
                let _loc = res.data.location
                if (_loc) {
                    return _loc
                } else {
                    return null
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    static searchAndSetLoctag(loc: string, loctags: LocationTag[], appo: Appointment): Appointment {
        if (!loctags) return

        let _loctag = loctags.find(l => l.keyword == loc)
        if (_loctag) {
            Logger(`loctagがありました: ${_loctag.keyword}`)
            appo.selectable_locations = [_loctag]
            let _appo = Appointment.setLocationSelectType(appo, [_loctag])
            appo = _appo
        } else {
            Logger(`loctagがないため、locationからはずします。 ${appo.location_name}`)
            appo.selectable_locations = null
            appo.location_tag_id = null
            let _appo = Appointment.setLocationSelectType(appo, [])
            appo = _appo
        }
        return appo
    }

    static getOnlineLocations(providers: string[] = [`zoom`]): Promise<CalendarTag[]> {
        return axios
            .get(`${Util.prefixUrl}/user_settings/connected_online_meeting_accounts`, {
                params: {
                    providers: providers,
                },
            })
            .then(res => {
                let _accounts = CalendarTag.fetchFromJson(res.data.accounts)
                Logger(`連携済みのloctagを取得してきました: providers:[${Util.output(providers)}] ${Util.output(_accounts)}`)
                return _accounts
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
