import { DateTime } from "luxon"
import { zeroPadding, Logger } from "../common"
import AvailableScheduleTag from "./AvailableScheduleTag"
import RoomMember from "./RoomMember"
import axios from "types/axios"
import Util from "packs/utils/Util"
import UserFile from "./UserFile"
import Notice from "./Notice"
import NotificationControl from "packs/utils/NotificationControl"
import Room from "./Room"

export default class RoomMessage {
    constructor(
        public id: string,
        public room_id: string,
        public member_id: string,
        public is_system: boolean,
        public type: number,
        public message: string,
        public created_at: number,
        public short_name: string,
        public name: string,
        public is_company: boolean,
        public role: number,
        public role_name: string,
        public available_schedule_tag: AvailableScheduleTag,
        public owner: RoomMember,
        public start_of_the_day: boolean,
        public parent_id: string,
        public files: UserFile[],
        public file_ids: string[]
    ) {}

    static fetchFromJson(messagesJson: any[]): RoomMessage[] {
        let messages = []
        for (let mes of messagesJson || []) {
            let _mes: RoomMessage = mes
            if (!_mes) continue
            // Logger(`mes: ${Util.output(_mes)}`)
            if (_mes.owner) {
                const _mem = RoomMember.fetchFromJson([_mes.owner])[0]
                _mes.owner = _mem
            }
            _mes.files = UserFile.fetchFromJson(_mes.files)

            messages.push(_mes)
        }
        return messages
    }

    static createUnsentMessage(userInfo: RoomMember, message: string, room_id: string) {
        // if userInfo.role_name = "member"
        return new RoomMessage(
            "sending",
            room_id,
            "",
            false,
            1,
            message,
            Util.getSec(),
            "",
            "",
            false,
            1,
            "member",
            null,
            userInfo,
            false,
            null,
            null,
            null
        )
    }

    setFileIds(files: UserFile[]) {
        let fileIds = []
        if (files && files.length > 0) {
            fileIds = files.map(f => f.file_id)
        }
        this.file_ids = fileIds
    }
    /**
     * メッセージの中に含まれるファイルを削除します。
     * @param messages
     * @param fileId
     */
    static deleteFileInMessages(messages: RoomMessage[], fileId: string) {
        for (let _mes of messages) {
            if (!_mes.files || _mes.files.length == 0) continue
            for (let _file of _mes.files) {
                if (_file.file_id == fileId) {
                    _mes.files = _mes.files.filter(f => f.file_id != fileId)
                    break
                }
            }
        }
    }

    static startChat(userInfo: RoomMember, room_id: string, message: string): Promise<any> {
        let params = {
            id: room_id,
            message: message,
            form: {
                attendee1_name: userInfo.name,
                attendee1_email: userInfo.email,
                attendee1_company_name: userInfo.company_name,
            },
        }

        return axios
            .post(`${Util.prefixUrl}/public_rooms/start_chat`, params)
            .then(res => {
                Logger(`res: ${res.data.message}`)
                Notice.message = `${res.data.message}`
                let uf: RoomMember = res.data.user_info
                if (uf) {
                    uf = RoomMember.fetchFromJson([res.data.user_info])[0]
                    let room = Room.fetchFromJson([res.data.room], uf.user_id)[0]
                    const roomLink: string = res.data.room_link
                    history.replaceState("", "", `${roomLink}`)
                    return { uf, room }
                } else {
                    return null
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    /**
     * ルームIDごとにメッセージを保存します。
     * @param messagesPerRoom [{room_id:, }]
     * @param room_id [string]
     * @param _messagesDic {total_num:, page:, messages:, }
     */
    static insertMessagesPerRoom(messagesPerRoom: any, room_id: string, _messagesDic: any) {
        let dic = messagesPerRoom[room_id] ? { ...messagesPerRoom[room_id] } : {}

        if (Util.isBlank(_messagesDic)) {
            // 箱だけつくっておきます。
            return dic
        }
        const messages: RoomMessage[] = RoomMessage.fetchFromJson(_messagesDic.messages)

        dic["total_num"] = _messagesDic["total_num"]
        dic["page"] = _messagesDic.page
        if (_messagesDic.page <= 1) {
            dic["messages"] = messages
        } else {
            Logger(`過去に取得したメッセージとドッキングします。`)
            Array.prototype.push.apply(messages, dic["messages"])
            dic["messages"] = messages
        }
        return dic
    }
}
