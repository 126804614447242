import { DateTime } from "luxon"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import axios from "axios"
import Notice from "packs/models/Notice"
import NotificationControl from "packs/utils/NotificationControl"
import RoomMember from "./RoomMember"

interface RoomsPermissions {
    rooms_all_tab: number
}

interface RoomSettingsPermissions {
    room_settings_create_private: number
    room_settings_use_chat: number
    room_settings_create_room: number
    room_settings_create_available_schedule_tag: number
}

interface AccountSettingsPermissions {
    account_settings_notifications: number
    account_settings_organization: number
    account_settings_security: number
    account_settings_members: number
}

interface ReportsPermissions {
    reports: number
    progresses: number
}

interface UserSettingsPermissions {
    user_settings_address_book: number
    user_settings_download: number
    user_settings_notifications: number
}

interface ShareSettingsPermissions {
    share_settings_my_calendar_tag: number
    // share_settings_my_meeting_room_tag: number
}

export default class UserPermissionsOption {
    static readonly PERMISSION_NONE = 0
    static readonly PERMISSION_READABLE = 10
    static readonly PERMISSION_WRITABLE = 100

    constructor(
        public rooms: RoomsPermissions,
        public room_settings: RoomSettingsPermissions,
        public account_settings: AccountSettingsPermissions,
        public reports: ReportsPermissions,
        public user_settings: UserSettingsPermissions,
        public share_settings: ShareSettingsPermissions
    ) {}

    static permissionTypes() {
        return [`なし`, `閲覧`, `編集可能`]
    }

    static permission2Types() {
        return [`なし`, `閲覧`]
    }

    static permissionEditTypes() {
        return [`なし`, `編集可能`]
    }
    static permissionShareTypes() {
        return [`共有しない`, `管理者のみに自動共有`, `全員に自動共有`]
    }

    static optionCategoryName(): any {
        return {
            rooms: `調整ページ一覧画面`,
            room_settings: `調整ページ/調整カレンダー`,
            account_settings: `アカウント画面`,
            reports: `レポート画面`,
            user_settings: `ユーザー設定画面`,
            share_settings: `グループ共有設定`,
        }
    }

    static optionNameDic(): any {
        return {
            rooms: { rooms_all_tab: { name: `全体タブの表示`, types: this.permission2Types() } },
            room_settings: {
                room_settings_create_private: { name: `鍵付き調整ページ作成`, types: this.permissionEditTypes() },
                room_settings_use_chat: { name: `チャット設置`, types: this.permissionEditTypes() },
                room_settings_create_room: { name: `調整ページ作成`, types: this.permissionEditTypes() },
                room_settings_create_available_schedule_tag: { name: `調整カレンダー作成`, types: this.permissionEditTypes() },
            },
            account_settings: {
                account_settings_notifications: { name: `外部通知の設定（全体）`, types: this.permissionEditTypes() },
                account_settings_organization: { name: `企業設定`, types: this.permissionEditTypes() },
                account_settings_security: { name: `セキュリティ`, types: this.permissionEditTypes() },
                account_settings_members: { name: `メンバーの招待`, types: this.permissionEditTypes() },
            },
            reports: {
                reports: { name: `レポート機能`, types: this.permission2Types() },
                progresses: { name: `進捗管理画面`, types: this.permission2Types() },
            },
            user_settings: {
                user_settings_address_book: { name: `アドレス帳の表示`, types: this.permissionEditTypes() },
                user_settings_download: { name: `調整ページデータのダウンロード`, types: this.permissionEditTypes() },
                user_settings_notifications: { name: `外部通知の設定（ユーザーに紐付く）`, types: this.permissionEditTypes() },
            },
            share_settings: {
                share_settings_my_calendar_tag: { name: `外部カレンダー連携時`, types: this.permissionShareTypes() },
                // share_settings_my_meeting_room_tag: { name: `Zoom/Teams連携時`, types: this.permissionShareTypes() },
            },
        }
    }

    static createDefault(role: string = `member`): UserPermissionsOption {
        const rooms = { rooms_all_tab: this.PERMISSION_READABLE }
        let room_settings = {
            room_settings_create_private: this.PERMISSION_NONE,
            room_settings_use_chat: this.PERMISSION_WRITABLE,
            room_settings_create_room: this.PERMISSION_WRITABLE,
            room_settings_create_available_schedule_tag: this.PERMISSION_WRITABLE,
        }
        let account_settings = {
            account_settings_notifications: this.PERMISSION_NONE,
            account_settings_organization: this.PERMISSION_NONE,
            account_settings_security: this.PERMISSION_NONE,
            account_settings_members: this.PERMISSION_NONE,
        }
        let reports = {
            reports: this.PERMISSION_NONE,
            progresses: this.PERMISSION_NONE,
        }
        let user_settings = {
            user_settings_address_book: this.PERMISSION_WRITABLE,
            user_settings_download: this.PERMISSION_WRITABLE,
            user_settings_notifications: this.PERMISSION_WRITABLE,
        }

        let share_settings = {
            share_settings_my_calendar_tag: this.PERMISSION_WRITABLE,
            // share_settings_my_meeting_room_tag: this.PERMISSION_WRITABLE,
        }

        if (role == `admin`) {
            room_settings = {
                room_settings_create_private: this.PERMISSION_WRITABLE,
                room_settings_use_chat: this.PERMISSION_WRITABLE,
                room_settings_create_room: this.PERMISSION_WRITABLE,
                room_settings_create_available_schedule_tag: this.PERMISSION_WRITABLE,
            }

            account_settings = {
                account_settings_notifications: this.PERMISSION_WRITABLE,
                account_settings_organization: this.PERMISSION_WRITABLE,
                account_settings_security: this.PERMISSION_WRITABLE,
                account_settings_members: this.PERMISSION_WRITABLE,
            }

            reports = {
                reports: this.PERMISSION_READABLE,
                progresses: this.PERMISSION_READABLE,
            }
        }

        return new UserPermissionsOption(rooms, room_settings, account_settings, reports, user_settings, share_settings)
    }

    // パーミッションを更新します.
    static update(po: UserPermissionsOption) {
        return axios({
            method: `put`,
            url: `${Util.prefixUrl}/permissions`,
            data: po,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }

                return res.data
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                // if (res.code === "ng_052") {

                // } else {
                //     Notice.message = `日程調整情報をうまく更新できませんでした。後ほどお試しください。`
                // }
                return null
            })
    }

    // パーミッションを更新します.
    static invite(
        emails: string[],
        role: number,
        permissions: UserPermissionsOption,
        createRoomTypes: string[]
    ): Promise<RoomMember[]> {
        return axios
            .post(`${Util.prefixUrl}/user_groups/invite`, {
                emails: emails,
                role: role,
                permissions: permissions,
                create_room_types: createRoomTypes,
            })
            .then(res => {
                Logger(`list: ${Util.output(res.data.invited_user)}`)
                const users = RoomMember.fetchFromJson(res.data.invited_users)
                Notice.message = res.data.message
                return users
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    // 閲覧可能か.
    static permitDisplay(userInfo: RoomMember, category: string, optionName: string) {
        if (Util.isBlank(userInfo)) return false
        let upo = userInfo.user_permissions_option
        if (Util.isBlank(upo)) return false
        let permission = upo[category][optionName]
        if (permission >= this.PERMISSION_READABLE) {
            return true
        }
        return false
    }

    // 編集可能か.
    static permitEdit(userInfo: RoomMember, category: string, optionName: string) {
        if (Util.isBlank(userInfo)) return false
        let upo = userInfo.user_permissions_option
        if (Util.isBlank(upo)) return false
        let permission = upo[category][optionName]
        if (permission == this.PERMISSION_WRITABLE) {
            return true
        }
        return false
    }
}
