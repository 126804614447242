import axios from "axios"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
// import qs from "qs"
// import isMobile from "ismobilejs"
import Appointment from "packs/models/Appointment"
import Util from "./Util"
import RoomMember from "packs/models/RoomMember"
import MessageTemplate from "packs/models/MessageTemplate"
import PossibleDate from "packs/models/PossibleDate"
import FormField from "packs/models/FormField"
import Const from "./Const"
import Room from "packs/models/Room"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
// declare var ics: any
// const ics = require("ics")
// const { writeFileSync } = require("fs")

//  便利系を記載.
export default class TemplateUtil {
    static readonly autoLinkText = `<<調整ページリンクが自動付与されます>>`
    static readonly onlineZoomDemoURL = `https://us06web.zoom.us/j/12345678910`
    static readonly onlineTeamsDemoURL = `https://teams.microsoft.com/l/meetup_join/12345678910`
    static readonly onlineGoogleMeetDemoURL = `https://meet.google.com/XXXXXXX`
    static getTitles() {
        return [
            `<<出席者-1-氏名>>`,
            `<<出席者-1-所属名>>`,
            `<<出席者-1-電話番号>>`,
            `<<出席者-2-氏名>>`,
            `<<出席者-2-所属名>>`,
            `<<出席者-2-電話番号>>`,
            `<<出席者-3-氏名>>`,
            `<<出席者-3-所属名>>`,
            `<<出席者-3-電話番号>>`,
            `<<オーナー名>>`,
            `<<自分-氏名>>`,
            `<<調整タイトル>>`,
            `<<所要時間>>`,
            `<<場所/オンライン>>`,
            `<<発行されたオンライン会議URL>>`,
            `<<確定日時>>`,
            `<<確定開始日時>>`,
            `<<確定終了日時>>`,
            `<<日程候補-確定リンクなし>>`,
            `<<日程候補-確定リンクあり>>`,
            `<<出席者-1-メールアドレス>>`,
            `<<出席者-2-メールアドレス>>`,
            `<<出席者-3-メールアドレス>>`,
            `<<担当者名>>`,
            `<<担当者紹介文>>`,
            `<<担当者変数-1>>`,
            `<<担当者変数-2>>`,
            `<<調整ページID>>`,
            `<<SMS送信用-調整ページリンク>>`,
        ]
    }

    // provider_calendar_eventに登録可能な変数
    static getFormVariables() {
        let vals = [
            `<<フォーム質問-1>>`,
            `<<フォーム回答-1>>`,
            `<<フォーム質問-2>>`,
            `<<フォーム回答-2>>`,
            `<<フォーム質問-3>>`,
            `<<フォーム回答-3>>`,
            `<<フォーム質問-4>>`,
            `<<フォーム回答-4>>`,
            `<<フォーム質問-5>>`,
            `<<フォーム回答-5>>`,
            `<<フォーム質問-6>>`,
            `<<フォーム回答-6>>`,
            `<<フォーム質問-7>>`,
            `<<フォーム回答-7>>`,
            `<<フォーム質問-8>>`,
            `<<フォーム回答-8>>`,
            `<<フォーム質問-9>>`,
            `<<フォーム回答-9>>`,
            `<<フォーム質問-10>>`,
            `<<フォーム回答-10>>`,
        ]
        return vals
    }

    static getBlockVariables() {
        let vals = [
            `<<オンライン会議ブロック>>`,
            `<<調整ページブロック>>`,
            `<<出席者ブロック>>`,
            `<<担当者ブロック>>`,
            `<<メモブロック>>`,
        ]
        return vals
    }

    static templatesForAI() {
        let vals = {
            textFieldType: [
                {
                    text: `<<FieldName>>が○○の場合にはこちらを利用してください。`,
                },
            ],
            textareaType: [
                {
                    text: `<<FieldName>>が、○○の相談内容の場合にはこちらを利用してください。`,
                },
            ],
            selectType: [
                {
                    text: `<<FieldName>>が<<FieldAnswer>>の場合はこちらを利用してください。`,
                },
            ],
            birthdateType: [
                {
                    text: `<<FieldName>>が2000年より前の場合はこちらを利用してください。`,
                },
            ],
            addressType: [
                {
                    text: `<<FieldName>>が東京都の場合はこちらを利用してください。`,
                },
            ],
        }
        return vals
    }

    // すべての変数を取得します.
    static getEventVariables() {
        let appoVals = this.getTitles()
        let formVals = this.getFormVariables()
        let blockVals = this.getBlockVariables()
        Array.prototype.push.apply(appoVals, formVals)
        Array.prototype.push.apply(appoVals, blockVals)
        return appoVals
    }

    /**
     * テンプレートに内容を代入します。
     * @param tmpText
     * @param members
     * @param owner
     * @param appo
     * @param userInfo
     * @param pds [PossibleDate[]]
     */
    static insertTemplateText(
        tmpText: string,
        members: RoomMember[],
        owner: RoomMember,
        appo: Appointment,
        userInfo: RoomMember,
        pds: PossibleDate[] = null,
        fields: FormField[] = null,
        defName: string = null,
        mailDisplayType: string = `rich`,
        room: Room = null,
        mag: MeetingAttendeesGroup = null
    ) {
        if (Util.isBlank(tmpText)) return ``
        let allValues = this.getEventVariables()
        let blockVals = this.getBlockVariables()
        let str = tmpText
        allValues.forEach((val, index) => {
            if (str.includes(val)) {
                Logger(`TemplateUtil.insertTemplateText val:${val}, index:${index} replaceします`)
                if (blockVals.includes(val) && defName == `custom` && mailDisplayType == `rich`) {
                    // カスタムの場合は、あえて置換しないでブロックのまま返します.
                    const re = new RegExp(val, "g")
                    str = str.replace(re, `\n${val}\n`)
                } else {
                    const name = this.getStringFromIndex(index, members, appo, userInfo, owner, pds, fields, room, mag)
                    Logger(`TemplateUtil.insertTemplateText name:${name}`)
                    const re = new RegExp(val, "g")
                    str = str.replace(re, name)
                }
            }
            // Logger(`strを置換していきます: ${str}`)
        })
        return str
    }

    // indexから変数にあるデータをセットします。
    static getStringFromIndex(
        index: number,
        members: RoomMember[],
        appo: Appointment,
        userInfo: RoomMember,
        owner: RoomMember,
        pds: PossibleDate[],
        fields: FormField[],
        room: Room,
        mag: MeetingAttendeesGroup
    ): string {
        // let titles = this.getTitles()
        switch (index) {
            case 0:
                return members[0] ? members[0].name || `` : ``
            case 1:
                return members[0] ? members[0].company_name || `` : ``
            case 2:
                return members[0] ? members[0].tel || `` : ``
            case 3:
                return members[1] ? members[1].name || `` : ``
            case 4:
                return members[1] ? members[1].company_name || `` : ``
            case 5:
                return members[1] ? members[1].tel || `` : ``
            case 6:
                return members[2] ? members[2].name || `` : ``
            case 7:
                return members[2] ? members[2].company_name || `` : ``
            case 8:
                return members[2] ? members[2].tel || `` : ``
            case 9:
                return owner ? owner.name || `` : ``
            case 10:
                return userInfo ? userInfo.name || `` : ``
            case 11:
                let name = Util.isPresent(appo) ? appo.name || `` : ``
                let apc = room ? room.appeal_content : null
                if (Util.isPresent(apc) && Util.isPresent(apc.title)) {
                    name = apc.title
                }
                return name
            case 12:
                return appo ? `${appo.duration}分` : ``
            case 13:
                return appo ? appo.location_name || `` : ``
            case 14:
                // 発行されたオンライン会議URL
                let locationName = appo ? appo.location_name || `` : ``
                if (locationName.includes(`(アカウント連携)`)) {
                    if (locationName.includes(`Google Meet`)) {
                        return this.onlineGoogleMeetDemoURL
                    } else if (locationName.includes(`Teams`)) {
                        return this.onlineTeamsDemoURL
                    } else if (locationName.includes(`Zoom`)) {
                        return this.onlineZoomDemoURL
                    }
                } else {
                    return ``
                }

            case 15:
                return appo ? appo.jp_format || `` : ``
            case 16:
                return appo ? (appo.jp_format || ``).split(`~`)[0] : ``
            case 17:
                return appo && appo.jp_format ? `${appo.jp_format.split("）")[0]}）${appo.jp_format.split("~")[1]}` || `` : ``
            case 18:
                return pds ? `${pds.map(pd => `${pd.jp_format}`).join(`\n`)}` : ``
            case 19:
                return pds
                    ? `${pds.map(pd => `<span class="underline">${pd.jp_format} この日程で確定する</span>`).join(`\n`)}`
                    : ``
            case 20:
                return members[0] ? members[0].email || `` : ``
            case 21:
                return members[1] ? members[1].email || `` : ``
            case 22:
                return members[2] ? members[2].email || `` : ``
            case 23:
                // `<<担当者名>>`,
                return mag ? mag.team_name || `` : ``
            case 24:
                // `<<担当者紹介文>>`,
                return mag ? mag.description || `` : ``
            case 25:
                // `<<担当者変数-1>>`,
                return mag ? mag.variable_1 || `` : ``
            case 26:
                // `<<担当者変数-2>>`,
                return mag ? mag.variable_2 || `` : ``
            case 27:
                return `調整ページID`
            case 28:
                return `https://waaq.jp/ss/XXXXX`

            //getEventVariables
            case 29:
                // 質問1
                return this.fieldsName(index, fields)
            case 30:
                // 回答1
                return this.fieldsName(index, fields)
            case 31:
                // 質問2
                return this.fieldsName(index, fields)
            case 32:
                // 回答2
                return this.fieldsName(index, fields)
            case 33:
                // 質問3
                return this.fieldsName(index, fields)
            case 34:
                // 回答3
                return this.fieldsName(index, fields)
            case 35:
                // 質問4
                return this.fieldsName(index, fields)
            case 36:
                // 回答4
                return this.fieldsName(index, fields)
            case 37:
                // 質問5
                return this.fieldsName(index, fields)
            case 38:
                // 回答5
                return this.fieldsName(index, fields)
            case 39:
                // 質問6
                return this.fieldsName(index, fields)
            case 40:
                // 回答6
                return this.fieldsName(index, fields)
            case 41:
                // 質問7
                return this.fieldsName(index, fields)
            case 42:
                // 回答7
                return this.fieldsName(index, fields)
            case 43:
                // 質問8
                return this.fieldsName(index, fields)
            case 44:
                // 回答8
                return this.fieldsName(index, fields)
            case 45:
                // 質問9
                return this.fieldsName(index, fields)
            case 46:
                // 回答9
                return this.fieldsName(index, fields)
            case 47:
                // 質問10
                return this.fieldsName(index, fields)
            case 48:
                // 回答10
                return this.fieldsName(index, fields)
            case 49:
                // `<<オンライン会議ブロック>>`,

                if (appo && appo.location_name == Const.zoomConnectAccount) {
                    return `【Zoom Meeting】\nアカウント: waaq@waaq.waaq\nミーティングID: 123456789012\nミーティングパスコード: 123456\n\n`
                }
                return ``
            case 50:
                // `<<調整ページブロック>>`,
                return `【waaq Link ページ】\n限定公開ページ: R1234567\n限定公開ページURL: https://waaq.test.jp/schedule?public_id=R1234567 ※ waaq Linkご利用ユーザーのみ有効なリンク\n公開ページURL: https://waaq.test.jp/p/waaq/R1234567\n\n`

            case 51:
                // `<<出席者ブロック>>`,
                return `【出席者】\n1\n\n`

            case 52:
                // `<<担当者ブロック>>`,
                return `【担当】\n\n\n`

            case 53:
                // `<<メモブロック>>`,
                return `【メモ】\n\n\n`
        }
    }

    static fieldsName(index: number, fields: FormField[]) {
        Logger(`${funcName()} index:${index}`)
        let num = index - this.getTitles().length
        let formindex = Math.floor(num / 2)
        if (fields) {
            let f = fields[formindex]
            if (f) {
                let name = f.field_name
                if (num % 2 == 0) {
                    return `${name}`
                } else {
                    return `「${name}」の回答`
                }
            }
        }
        return ``
    }

    static regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    static mailRegex =
        /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/

    static buttonRegex = /\[.*\]\((https?|ftp)(:\/\/[-_.!~*\\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)\)/
    static buttonTextRegex = /\[.*\]/
    static buttonLinkRegex = /\((https?|ftp)(:\/\/[-_.!~*\\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)\)/
    static buttonRegexWithOptions = /\[.*\]\((https?|ftp)(:\/\/[-_.!~*\\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)\)\{.*\}/
    static buttonOptionsRegex = /\{.*\}/
    static telRegex1 = /0(\d-\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{4}-\d)-\d{4}/ //固定
    static telRegex2 = /(050|070|080|090)-\d{4}-\d{4}/ //携帯
    static telRegex3 = /0120-\d{3}-\d{3}/ //フリーダイヤル

    static processText(text: string) {
        if (Util.isBlank(text)) return []

        try {
            let processText = text

            let match
            let matchMail
            let matchObj = {}
            let count = 0
            let matchButton
            let matchButtonWithOptions
            let matchTel

            while ((matchButtonWithOptions = this.buttonRegexWithOptions.exec(processText)) != null) {
                Logger(matchButtonWithOptions)
                count += 1
                matchObj[`[${count}]`] = matchButtonWithOptions[0]
                processText = processText.replace(matchButtonWithOptions[0], `[${count}]`)
            }

            while ((matchButton = this.buttonRegex.exec(processText)) != null) {
                Logger(matchButton)
                count += 1
                matchObj[`[${count}]`] = matchButton[0]
                processText = processText.replace(matchButton[0], `[${count}]`)
            }

            while ((match = this.regex.exec(processText)) != null) {
                count += 1
                matchObj[`[${count}]`] = match[0]
                processText = processText.replace(match[0], `[${count}]`)
            }

            while ((matchMail = this.mailRegex.exec(processText)) != null) {
                Logger(matchMail)
                count += 1
                matchObj[`[${count}]`] = matchMail[0]
                processText = processText.replace(matchMail[0], `[${count}]`)
            }

            while ((matchTel = this.telRegex1.exec(processText)) != null) {
                Logger(matchTel)
                count += 1
                matchObj[`[${count}]`] = matchTel[0]
                processText = processText.replace(matchTel[0], `[${count}]`)
            }
            while ((matchTel = this.telRegex2.exec(processText)) != null) {
                Logger(matchTel)
                count += 1
                matchObj[`[${count}]`] = matchTel[0]
                processText = processText.replace(matchTel[0], `[${count}]`)
            }
            while ((matchTel = this.telRegex3.exec(processText)) != null) {
                Logger(matchTel)
                count += 1
                matchObj[`[${count}]`] = matchTel[0]
                processText = processText.replace(matchTel[0], `[${count}]`)
            }

            const keys = Object.keys(matchObj)

            keys.forEach(key => {
                processText = processText.replace(key, `,${matchObj[key]},`)
            })

            return processText.split(",")
        } catch (e) {
            Logger(`${funcName()} e:${e}`)
            return []
        }
    }

    static filledFieldsExamples(ffs: FormField[]) {
        let tempsDic = TemplateUtil.templatesForAI()
        let lists = []
        for (let ff of ffs) {
            if (ff.is_special) continue

            if ([`input`].includes(ff.field_type)) {
                let temp = tempsDic[`textFieldType`][0][`text`]
                let _tmpText = temp.replace(`<<FieldName>>`, ff.field_name)
                lists.push(_tmpText)
            } else if ([`textarea`].includes(ff.field_type)) {
                let temp = tempsDic[`textareaType`][0][`text`]
                let _tmpText = temp.replace(`<<FieldName>>`, ff.field_name)
                lists.push(_tmpText)
            } else if (
                FormField.selectableTypes.includes(ff.field_type) ||
                FormField.selectableWithImageTypes.includes(ff.field_type)
            ) {
                let temp = tempsDic[`selectType`][0][`text`]
                let _tmpText = temp.replace(`<<FieldName>>`, ff.field_name)
                let val = ff.selectable_values[0]
                _tmpText = _tmpText.replace(`<<FieldAnswer>>`, val ? val.value : `○○`)
                Logger(`ff:${Util.output(ff)}, temp:${temp}, _tmpText:${_tmpText}, val:${Util.output(val)}`)

                lists.push(_tmpText)
            } else if (ff.field_type == `birthdate`) {
                let temp = tempsDic[`birthdateType`][0][`text`]
                let _tmpText = temp.replace(`<<FieldName>>`, ff.field_name)

                lists.push(_tmpText)
            } else if (ff.field_type == `address`) {
                let prefec = ff.selected_values[1]
                let temp = tempsDic[`addressType`][0][`text`]
                let _tmpText = temp.replace(`<<FieldName>>`, ff.field_name)

                lists.push(_tmpText)
            } else {
                continue
            }
        }
        return lists
    }
}
