import CalendarUtil from "packs/utils/CalendarUtil"
import AssignForm from "packs/models/AssignForm"
import FormField from "packs/models/FormField"
import { reactive } from "vue"
import RoomMember from "packs/models/RoomMember"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"
import AssignCondition from "packs/models/AssignCondition"

/**
 * 主にルームを作成/更新する際に利用します。
 */
interface FormCreateManager {
    aform: AssignForm
    fields: FormField[]
    userInfo: RoomMember
    conds: any
    hasUnsaved: boolean

    setNew(...args: any[])
    createNewForm(name)
    reset()
    updateFormFields(fields: FormField[])
    updateConditions(conditions: AssignCondition[])
    editStartAform()
    editEndAform(aform: AssignForm)
    createFieldConverter()
}

let _FormCreateManager: FormCreateManager = reactive({
    aform: null,
    fields: null,
    userInfo: null,
    conds: null,
    hasUnsaved: false,

    setNew(userInfo: RoomMember, action: string, form: AssignForm = null) {
        Logger(`FCM#setNew action:${action}, form:${Util.output(form)}`)
        this.reset()

        if (action == `edit` && form) {
            this.aform = form
            this.fields = this.aform.form_fields || []
            this.conds = this.aform.assign_conditions || []
        } else if (action == `copy` && form) {
            let _form = AssignForm.copyForm(form)

            this.aform = _form
            this.fields = this.aform.form_fields || []
            this.conds = this.aform.assign_conditions || []
        } else {
            this.aform = AssignForm.createDefault()
            this.fields = []
            this.conds = []
        }
        this.userInfo = userInfo
    },
    createNewForm(name) {
        this.aform = AssignForm.createDefault()
        this.aform.title = name
        this.fields = []
    },
    reset() {
        this.aform = null
        this.fields = null
        this.conds = null
    },
    updateFormFields(fields: FormField[]) {
        this.fields = fields
        this.aform.form_fields = fields
    },
    updateConditions(conditions: AssignCondition[]) {
        this.conds = conditions
        this.aform.assign_conditions = conditions
    },

    editStartAform() {
        Logger(`FCM#editStartForm ${Util.output(this.aform)}`)
        if (!this.aform) return
        this.edittingForm = null

        return this.edittingForm
    },
    editEndAform(aform: AssignForm) {
        this.aform = aform
        this.edittingAstag = null
    },
    createFieldConverter() {
        // this.rcm.showLeftPanelContentWithType(`field_converter`)
    },
})
export default _FormCreateManager
