import { DateTime } from "luxon"
import AvailableScheduleTag from "./AvailableScheduleTag"
import Util from "packs/utils/Util"
import RoomMember from "./RoomMember"

interface OwnerInfo {
    email: string
    image_url: string
    name: string
}

export default class CalendarTag {
    static readonly typeDisplay: number = 1

    constructor(
        public id: string, //MeetingGroup_idが入る可能性があるので、ctag_idをみたい場合は、calendar_tag_idを参照すること.
        public email: string, //ev.calendar_idと一致.
        public search_key: string,
        public name: string,
        public writable: boolean,
        public can_register_calendar: boolean,
        public ignore_allday: boolean,
        public type: number,
        public can_use_available_event_keyword: boolean,
        public available_event_keyword: string,
        public ignore_event_keyword: string,
        public can_use_ignore_event_keyword: boolean,
        public required: boolean, // 必須参加者か.
        public available_event_allday_start_hour: number,
        public available_event_allday_start_min: number,
        public available_event_allday_end_hour: number,
        public available_event_allday_end_min: number,
        public systemUpdatedAt: number,
        public calendar_tag_id: string,
        public image_url: string,

        // online_meeting_resource用.
        public is_owner: boolean,
        public provider: string,
        public is_provider_default: boolean,

        // 投票形式用
        public needVote: boolean,

        // v8追加
        public owner: OwnerInfo,

        public is_active_meeting_attendee_option: boolean,
        public is_shared_in_waaq: boolean,
        public is_primary_calendar: boolean
    ) {}

    /**
     *
     * @param ctagsJson
     * @param from "mag"/"ctag" magで足らないパラメータ,ctagで足らないパラメータをそれぞれ埋める.
     */
    static fetchFromJson(ctagsJson: any, from: string = `ctag`): CalendarTag[] {
        let ctags = []
        if (!ctagsJson) return ctags
        if (ctagsJson.length == 0) return ctags

        for (let ctag of ctagsJson) {
            let _ctag: CalendarTag
            if (from == `ctag`) {
                // ctag情報にmagと共通の情報を仕込みます。
                _ctag = this.convertFromServerCtag(ctag)
            } else {
                // magにctagの情報を仕込みます。
                _ctag = this.convertFromMag(ctag)
            }

            _ctag.systemUpdatedAt = Util.getSec()
            ctags.push(_ctag)
        }
        return ctags
    }

    static createDefault() {
        const _t = Util.getSec()

        // const dic = {email: null, image_url: null, name: null} as OwnerInfo
        return new CalendarTag(
            `newId`,
            ``,
            ``,
            ``,
            false,
            true,
            false,
            1,
            false,
            null,
            null,
            false,
            true,
            null,
            null,
            null,
            null,
            _t,
            ``,
            null,
            false,
            null,
            false,
            false,
            null,
            false,
            null,
            null
        )
    }

    /**
     *
     * @param _ctag こちらに詰め込む
     * @param ctag
     */
    static convertFromServerCtag(ctag: CalendarTag): CalendarTag {
        let _ctag = this.createDefault()
        const returnedTarget = Object.assign(_ctag, ctag)
        return returnedTarget
    }

    /**
     *
     * @param _ctag こちらに詰め込む
     * @param ctag
     */
    static convertFromMag(ctag: CalendarTag): CalendarTag {
        let _ctag = this.createDefault()
        const returnedTarget = Object.assign(_ctag, ctag)
        return returnedTarget
    }

    static toMember(ctag: CalendarTag) {
        let mem = RoomMember.createDefaultClient()
        let _owner = ctag.owner
        mem.email = _owner.email
        mem.name = _owner.name
        mem.image_url = _owner.image_url
        return mem
    }

    static includesNameOrEmail(ctag: CalendarTag, text: string): boolean {
        let _text = text.toLowerCase()
        return (ctag.name && ctag.name.toLowerCase().includes(_text)) || (ctag.email && ctag.email.toLowerCase().includes(_text))
    }
}
